import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Policy from '../components/PrivacyPolicy/PrivacyPolicy'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import { PRIVACY_POLICY as PRIVACY_POLICY_LINK } from '../components/internal-links'
import { generateBreadcrumbsSchema } from '../lib/generate-schema'

const breadcrumbs: Breadcrumb[] = [
  {
    title: 'Homepage',
    url: '/',
  },
  {
    title: 'Privacy Policy',
    url: PRIVACY_POLICY_LINK,
  },
]

const PrivacyPolicy: React.FC = () => (
  <Layout showBreadcrumbs breadcrumbs={breadcrumbs}>
    <Helmet>
      {process.env.GATSBY_STAGING === 'true' && (
        <meta name="robots" content="noindex" />
      )}
    </Helmet>
    <SEO
      title="Privacy Policy: Read About the Privacy Policy at MegaRummy"
      description="Read about the privacy policy at MegaRummy. Get to know about data collection, usage, and protection by MegaRummy. Click to know more!"
      breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
    />
    <Policy />
  </Layout>
)

export default PrivacyPolicy
