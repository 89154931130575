import React from 'react'
import Container from 'react-bootstrap/esm/Container'

import './PrivacyPolicy.scss'

const PrivacyPolicy: React.FC = () => {
  return (
    <div id="privacy-policy">
      <Container>
        <h1>Privacy Policy</h1>
      </Container>
      <section>
        <Container>
          <p>
            Megashots Internet Private Limited (Mega), a company registered
            under the Companies Act, 2013 and with its registered office at #25,
            8th Main, 2nd Floor, Vasanthnagar, Bangalore, Karnataka 560 052,
            (“MegaRummy” or “We” or “Us” or “Our” or “Company”), owns and
            manages
          </p>
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              the website&nbsp;
              <a href="http://www.getmegarummy.com">www.getmegarummy.com</a>;
            </li>
            <li>
              the MegaRummy application (“App”), a skill-based rummy
              application; and
            </li>
            <li>
              such other applications, platforms and games which may be made
              available from time to time (together “Platform” or “Platforms”)
              through which You have opted to avail of certain Services by
              agreeing to the terms of use (“Terms of Use”), available on the
              home screen of the Platform.
            </li>
          </ol>
          <p>
            For the purposes of this Privacy Policy, unless defined hereunder,
            all capitalized terms shall have the meaning ascribed to them in the
            Terms of Use.
          </p>
        </Container>
      </section>

      <section className="no-pad-top">
        <Container>
          <h2>Your privacy is very important to MegaRummy</h2>
          <p>
            At MegaRummy, we believe in being absolutely transparent about our
            Privacy Policy so that you can focus on playing on the platform
            without worrying.
          </p>
          <p>
            We maintain complete confidentiality of all your personal
            information (“Personal Information”) collected by us on our
            Platform. We have very thorough methods to safeguard your data. This
            Privacy Policy forms a crucial part of the Terms of Use.
          </p>
          <p>
            This Privacy Policy is a statement of MegaRummy’s policies and
            procedures for collection, use, storage, processing, disclosure and
            protection of any Personal Information which you may be required to
            give or make available on the Platforms in order to use the
            Platforms.
          </p>
          <p>
            This Privacy Policy applies to all current and former users
            accessing or using Our Platforms, or otherwise engaging with us
            through email or other means (collectively, “You” or “Your” or
            “Users”). We recommend that you go through this Privacy Policy
            carefully before accessing or using the Platform.
          </p>
        </Container>
      </section>

      <section className="white-bg">
        <Container>
          <h2>
            Our goal is to offer a safe, reliable and secure environment to
            play.
          </h2>
          <p>
            MegaRummy respects your privacy and thus assures you that any
            information provided by you to MegaRummy is protected and will be
            dealt with according to this Privacy Policy.
          </p>
          <p>
            MegaRummy shall not provide, distribute, trade, sell or rent your
            name, e-mail address or other personal information provided on this
            site to any third party or information related to your usage of the
            Platforms, except as provided in this Privacy Policy.
          </p>
          <p>
            By using the Service, you (“User” or “you”) agree to the terms of
            this Privacy Policy. We reserve the right to amend this Privacy
            Policy and changes will come into effect immediately upon such
            change being notified on the Sites. Your continued use of the
            Service constitutes your agreement to this Privacy Policy and any
            amendments to the same thereinafter. Our Terms of Use take
            precedence over any conflicting provision of this Privacy Policy.
            Unless otherwise defined herein, capitalised terms shall have the
            meaning given to them in our Terms of Use.
          </p>
        </Container>
      </section>

      <section>
        <Container>
          <h2>What information we collect</h2>
          <ul>
            <li>
              To avail the facilities offered on{' '}
              <a href="http://www.getmegarummy.com">www.getmegarummy.com</a> or
              through the MegaRummy app, you will have to create a Login ID
              including your Username, Password and a Valid Email address.
            </li>
            <li>
              Additionally, for participating in any tournaments / games /
              competitions organized by MegaRummy where a pay-out is involved,
              you will need to submit your Name, Date of Birth, Telephone
              number, Postal/Contact address, bank account details, and PAN
              number. We may also collect information about Facebook account ID
              and sign-in information, Facebook profile photo, Facebook account
              ID of Facebook friends, Google account ID, PAN Number, picture of
              any of the government issued photo identity and address proof such
              as driving license, voter ID, Aadhaar card, passport and other
              associated details required for your KYC forms, PAN card, contact
              details from your mobile address book, and details of your
              WhatsApp profile (collectively, “Account Information”).
            </li>
            <li>
              In addition, you might voluntarily submit additional information
              including profile pictures, nick names, social media profiles,
              Aadhar number etc.
            </li>
            <li>
              When you visit www.getmegarummy.com, certain information may also
              be automatically collected and stored, including the IP address of
              your computer, browser type and language, the date and the time
              during which you accessed the site, UDID and MAC data,
              geo-location data, the address of the website which you may have
              used to link to MegaRummy.
            </li>
            <li>
              When you visit the MegaRummy Platforms, cookies may be left in
              your computer. A cookie is a small text file that uniquely
              identifies your browser. The cookies assigned by the servers of
              MegaRummy may be used to personalize your experience on the
              Platforms. Moreover, cookies may also be used for authentication,
              game management and security purposes.
            </li>
            <li>
              MegaRummy may also use software applications for website traffic
              analysis and to gather statistics used for advertising and for
              determining the efficacy and popularity of the Platforms among
              others.
            </li>
            <li>
              Cookies may also be assigned by the advertisers on the Platforms
              when you click on any of the advertisements which may be displayed
              on the Platforms in which case such cookies are controlled by
              these advertisers and not MegaRummy.
            </li>
            <li>
              We record your playing history on the Platforms including hands
              played, money deposited, wagered, won or withdrawn, game session
              durations, your high scores, your preferred game settings, and
              more broadly all actions taken within our software will be logged.
            </li>
            <li>
              We collect information about your use of our Services, such as
              applications&apos; usage, log files, user activity (e.g. pages
              viewed, the amount of time spent on particular pages, online
              browsing, clicks, actions, etc.), time stamps, alerts, etc. This
              information is collected for amongst other things troubleshooting
              errors and bugs as well as for research and analytics purposes
              about your use of the Services.
            </li>
            <li>
              We may collect Personal Information from third party service
              providers, such as information about your credit history from
              credit agencies and other financial information which is relevant
              to the provision of our services, as well as information which is
              gathered in order to verify your identity and prevent fraudulent
              or illegal activity.
            </li>
          </ul>
        </Container>
      </section>

      <section className="no-pad-top">
        <Container>
          <h2>How MegaRummy uses the collected information</h2>
          <ul>
            <li>
              When you register your email address with MegaRummy, you agree to
              receive email communication from MegaRummy, entities specifically
              authorized by MegaRummy and other users. You also agree and
              acknowledge that when you use our referral program for referring
              someone, MegaRummy will send emails to that person on your behalf
              and the email headers will carry your email address as the address
              from which such emails are sent.
            </li>
            <li>
              MegaRummy has a policy of not sharing any personally identifiable
              information with anyone other than entities specifically
              authorized by MegaRummy which may include advertisers and sponsors
              of MegaRummy. However, MegaRummy may use your name, Login ID and
              the state from where you are participating when announcing the
              results of any contests run on the Platforms. Such contests are
              further governed by the Terms and Conditions which shall be
              available on the Platforms as and when such a contest is run on
              the Platforms.
            </li>
            <li>
              MegaRummy conducts periodic analysis and survey of the traffic to
              MegaRummy for market research and advertising purposes.
            </li>
            <li>
              MegaRummy reserves the right to share your registration
              information with MegaRummy appointed market research and
              advertising companies or firms from time to time for the said
              purposes.
            </li>
            <li>
              MegaRummy may also use cumulative non-personal information for
              auditing and analysis purposes with the aim of improving its
              services.
            </li>
            <li>
              Your playing history including hands played, money deposited or
              withdrawn, and other actions taken within our software can be
              analysed by us for the purpose of graphical displays, user
              feedback, analytics and marketing either by us or by associate
              companies engaged for this purpose.
            </li>
            <li>
              We may anonymize or de-identify the information collected by the
              services or via other means so that the information cannot, on its
              own, personally identify you. Our use and disclosure of such
              aggregated or de-identified information is not subject to any
              restrictions under this Privacy Policy, and we may disclose it to
              others without limitation and for any purpose, such as for
              advertising or marketing purposes.
            </li>
            <li>
              If we should ever file for bankruptcy, or become insolvent, or be
              acquired by a third party, merge with a third party, sell all or
              part of Our assets, or transfer substantially all or part of Our
              relevant assets to a third party, we are entitled to share all the
              information you have provided through the Services to our
              business, investment, and merger partners. The same would apply
              (under obligations of confidentiality / non-disclosure agreements)
              for potential business, investment, and merger partners.
            </li>
            <li>
              Additionally, MegaRummy may share personally identifiable
              information if sharing of such information is necessary to comply
              with legal processes or governmental request, or to enforce the
              Terms of Use and this Privacy Policy, or for prevention of fraud,
              or for issues involving information security, or to protect your
              rights or the rights of MegaRummy or the rights of the general
              public.
            </li>
          </ul>
        </Container>
      </section>

      <section className="white-bg">
        <Container>
          <h2>User&apos;s Responsibility</h2>
          <ul>
            <li>
              When you register with MegaRummy, your account is protected by
              means of login information which includes a username and a
              password that is known only to you. Therefore, you are responsible
              for maintaining the confidentiality of your login information
              including your username and password.
            </li>
            <li>
              We suggest you do not disclose your password to anyone. If you
              become aware of or reasonably suspect any breach of security,
              including compromise of your login information, it is your
              responsibility to immediately notify MegaRummy.
            </li>
            <li>
              MegaRummy may contain links to other websites. Such websites are
              governed by their own privacy policies and MegaRummy does not
              exercise any control over them. It is your responsibility to read
              and understand the privacy policy of such websites when you follow
              a link outside MegaRummy. MegaRummy is not responsible for the
              privacy practices or the content of such websites.
            </li>
            <li>
              Our services are not intended for or directed at persons under the
              age of eighteen (18). For any person who informs us that his age
              is eighteen (18) years or above, we reserve the right to access
              and verify the person&apos;s Personal Information, but the
              responsibility for not submitting any incorrect personal data
              remains with the user.
            </li>
            <li>
              Data which is transmitted over the Internet is intrinsically
              exposed to security risks or threats. For instance, information
              transmitted via chat or email can be compromised and used by
              others. Therefore, MegaRummy cannot guarantee any security for
              such information.
            </li>
          </ul>
        </Container>
      </section>

      <section>
        <Container>
          <h2>Consent</h2>
          <ul>
            <li>
              By using our Platforms, it is understood that you have given your
              consent to the collection and use of the information listed in the
              “Information gathered” section by MegaRummy and its partners and
              affiliates.
            </li>
            <li>
              In order to play real money games, you will be required to send
              money to and receive money from us. We may use third-party
              electronic payment processors and/or financial institutions
              (&apos;ESPs&apos;) to process such financial transactions.
            </li>
            <li>
              By accepting this Privacy Policy, you expressly consent to
              Personal Information necessary for the processing of transactions
              being shared with ESPs. We take steps to ensure that our
              arrangements with ESPs protect your privacy.
            </li>
            <li>
              Our services are not intended for or directed at persons under the
              age of eighteen (18). For any person who informs us that his age
              is eighteen (18) years or above, we reserve the right to access
              and verify the person&apos;s Personal Information. If we become
              aware that a minor has attempted to or has submitted personal
              information via the Services, we will not accept this information
              and will take steps to remove such information from our records in
              a secure manner.
            </li>
            <li>
              We reserve the right to conduct a security review at any time to
              validate your identity, age, the registration data provided by you
              and to verify your use of the services and your financial
              transactions for potential breach of our Terms of Use and of
              applicable law. By using our website, you authorize us, our staff,
              agents and suppliers to use your Personal Information and to
              disclose your Personal Information to third parties for the
              purposes of validating the information you provide to us.
            </li>
            <li>
              By filling your mobile number on our site, you are giving
              MegaRummy and its affiliates the right to contact you by Call/SMS
              to provide you information on all promotional activity/events
              conducted by MegaRummy and its partners. Your mobile number will
              not be used for any third-party communication whatsoever.
            </li>
            <li>
              Our software package contains certain features designed to detect
              use of automated programs that enable artificial (non-human)
              intelligence to play on our site. Use of such &apos;bot&apos;
              software programs violates Our Terms of Use. By installing Our
              software and using the Services, you consent to our software
              performing any or all of the following functions:
            </li>
            <li>
              Scan your list of active software applications while you are using
              the services;
            </li>
            <li>
              Scan your list of active processes while you are using the
              services; and
            </li>
            <li>
              Scan the files in your services and site-related program folders
              to ensure that only &apos;non-hacked&apos; versions of our
              software are being used.
            </li>
            <li>
              If any of the foregoing processes reveals a suspect application or
              process, our software may scan the files associated with the
              suspect application or process and compile a composite mesh (i.e.,
              a profile that characterizes the files associated with the
              application or process) to be matched against profiles for known
              illicit automated programs.
            </li>
            <li>
              Our software will not perform any random search of large portions
              of Your hard drive, equipment or files, and it will not transmit
              any other information to us or to any third party other than as
              described here.
            </li>
            <li>
              You consent to our sharing your information with any third parties
              who organize offline events or tournaments on behalf of or in
              conjunction with MegaRummy.
            </li>
            <li>
              You consent to sharing information with MegaRummy’s Auditors,
              contractors or business process advisors
            </li>
          </ul>
        </Container>
      </section>

      <section className="no-pad-top">
        <Container>
          <h2>How do we protect your Personal Information?</h2>
          <p>
            In order to protect your Personal Information in line with the
            applicable data privacy laws and regulations, we have implemented
            all appropriate technical and organizational measures as required
            under applicable law. Please be aware that the transmission of
            information via the internet is not completely secure. Although we
            will do our best to protect your Personal Information, we cannot
            guarantee the security of your data transmitted through the
            Platforms. Once we have received your information, we will use
            strict physical, electronic, and procedural safeguards to try to
            prevent unauthorised access. We request that you cooperate with us
            in protecting your Personal Information and comply with the
            following personal security measures:
          </p>
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              Do not give third parties access to your computer or other devices
              when you access the Platform;
            </li>
            <li>
              Keep your login credentials, including access code and passwords
              for your computer or other devices, as well as your login
              credentials, confidential;
            </li>
            <li>
              Update your antivirus software when requested by your antivirus
              software provider; and
            </li>
            <li>
              Do not send any confidential information by using the Platform
              and/or the Services.
            </li>
          </ol>
          <p>
            You agree that the security measures adopted by us are reasonable to
            protect your Personal Information. We disclaim any liability as a
            result of any breach of security or unintended loss or disclosure of
            your Personal Information.
          </p>
        </Container>
      </section>

      <section className="no-pad-top">
        <Container>
          <h2>
            Data Retention and Your Rights concerning Your Personal Information
          </h2>
          <ul>
            <li>
              Your Personal Information may be retained and may continue to be
              used until: (i) the relevant purposes for the use of your
              information described in this Privacy Policy are no longer
              applicable; or (ii) we are no longer required by applicable law,
              regulations, contractual obligations or legitimate business
              purposes to retain your Personal Information and the retention of
              your Personal Information is not required for the establishment,
              exercise or defense of any legal claim; or (iii) You request us to
              delete your Personal Information.
            </li>
            <li>
              You may request deletion of your Personal Information, withdraw
              your consent or limit your consent to us for collecting and
              processing your Personal Information for the purposes specified
              herein by writing to our customer support team at
              ”help@getmega.com”. Additionally, you may also request deletion of
              your Personal Information through the “Contact Us” section on the
              Platforms. Following your deletion request, we may, however,
              reserve the right to retain and store your Personal Information
              for our business purposes to the extent permitted by law or for
              complying with law or enforcing our legal rights.
            </li>
            <li>
              Please note that your withdrawal of consent may result in us not
              being able to provide you with the Services or terminate any
              existing relationship that we may have with you.
            </li>
            <li>
              Please also note that deletion of your Personal Information
              collected by third-party websites accessed through our Platforms
              is governed by the privacy policies of such third party websites.
              MegaRummy is not responsible for the deletion of Personal
              Information collected by third-party websites.
            </li>
            <li>
              You may review, correct, update and change the information that
              you have provided to us, at any point by writing to us at
              ”help@getmega.com”. Should you choose to update your Personal
              Information or modify it in a way that is not verifiable by us, or
              leads to such information being incorrect, we will be unable to
              provide you with access to our Platform or the Services. We
              reserve the right to verify and authenticate your identity and
              your Personal Information in order to ensure that we are able to
              offer you all our Services. We can only keep your Personal
              Information up-to-date and accurate to the extent you provide us
              with the necessary information. It is your responsibility to
              notify us of any changes in your Personal Information. Access to
              or correction, updating or deletion of your Personal Information
              may be denied or limited by us if it would violate another
              person’s rights and/or is not otherwise permitted by applicable
              law.
            </li>
          </ul>
        </Container>
      </section>

      <section className="no-pad-top">
        <Container>
          <h2>Applicable Law and Jurisdiction</h2>
          <p>
            By visiting the Platforms, you agree that the laws of the Republic
            of India without regard to its conflict of laws principles, govern
            this Privacy Policy and any dispute arising in respect hereof shall
            be subject to and governed by the dispute resolution process set out
            in the Terms of Use.
          </p>
        </Container>
      </section>

      <section className="no-pad-top">
        <Container>
          <h2>Updates or Amendments to the Privacy Policy</h2>
          <ul>
            <li>
              We may revise this Privacy Policy from time to time, including
              without limitation as a result of legal and regulatory changes,
              security reasons and changes to our services, and the most current
              version will always be posted on the Platforms. We encourage you
              to review this Privacy Policy regularly for any changes.
            </li>
            <li>
              Your continued use of our services, following the notification of
              such amendments on the Platforms, constitutes your acknowledgement
              and consent of such amendments to the Privacy Policy and your
              agreement to be bound by the terms of such amendments.
            </li>
          </ul>
        </Container>
      </section>

      <section className="no-pad-top no-pad-bottom">
        <Container>
          <h2>Grievances</h2>
          <p>
            In accordance with the IT Act and rules made thereunder, the name
            and contact details of the Grievance Officer are provided below:
          </p>
          <p className="mb-0">Name: Amrit Raj</p>
          <p>Email: amrit@getmega.com</p>
          <p>
            For any questions, feedback or complaints in relation to any matter
            concerning this Privacy Policy, You may contact the above designated
            Grievance Officer.
          </p>
        </Container>
      </section>
    </div>
  )
}

export default PrivacyPolicy
